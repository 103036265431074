<template>
  <b-card>
    <div class="d-flex justify-content-center mb-1" v-if="loading">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <div v-if="!loading">
      <b-table
        :fields="fields"
        :items="items"
        responsive
        :per-page="perPage"
        v-if="items.length"
      >
        <template #cell(index)="data">
          {{ data.index + 1 + perPage * (currentPage - 1) }}
        </template>
        <template #cell(deleted_at)="data">
          <div class="d-flex">
            {{ data.item.deleted_at | formatdate }}
          </div>
        </template>
        <template #cell(action)="data">
          <div class="d-flex">
            <b-button variant="primary" size="sm" @click="revert(data.item)">
              <span v-if="isloading && data.item.org_id == organization"
                ><b-spinner type="border" small></b-spinner> Please wait</span
              >
              <span v-else>Revert</span>
            </b-button>
            <b-button
              variant="danger"
              class="ml-1"
              size="sm"
              @click="gotopermanentDelete(data.item.org_id, data.item.org_name)"
            >
              Delete
            </b-button>
          </div>
        </template>
      </b-table>
      <div v-else>
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
        >
          <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Data Found</h3>
        </div>
      </div>
    </div>
    <b-pagination-nav
      v-model="currentPage"
      :numberOfPages="total"
      :link-gen="linkGen"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="dataTable"
      use-router
    />

    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text style="max-width: 100%; word-wrap: break-word">
        Do you really want to permanent delete
        <span
          class="text-primary"
          style="max-width: 100%; display: inline-block"
          >"{{ org_name }}"</span
        >
        ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="permanentDelete()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </b-card>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BModal,
  BCardText,
  BPaginationNav,
} from "bootstrap-vue";
import moment from "moment";
export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BModal,
    BCardText,
    BPaginationNav,
  },
  data() {
    return {
      organization: "",
      org_name: "",
      items: [],
      fields: [
        { key: "index", label: "#" },
        // { key: "org_id", label: "ORG ID" },
        { key: "org_name", label: "Organization Name" },
        { key: "org_nick_name", label: "Organization Nickname" },
        { key: "org_address", label: "Organization Address" },
        { key: "deleted_by", label: "Deleted By" },
        { key: "deleted_at", label: "Deleted At" },
        { key: "action", label: "Action" },
      ],
      openDeleteModal: false,
      isloading: false,
      pagination_pos: "center",
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
    };
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.load();
    },
  },
  created: function () {
    this.load();
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    load: function (search = false) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/deleted-orgs/?page=" +
          this.currentPage,
      };
      this.$http(options).then((res) => {
        console.log(res.data);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
      });
    },

    revert: function (org) {
      this.isloading = true;
      this.organization = org.org_id;
      let formData = new FormData();
      formData.append("org_id", this.organization);

      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: formData,
        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/revert-organization/",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$store.state.app.organizations.push({
            value: org.org_id,
            text: org.org_name,
          });
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.isloading = false;
        this.load();
      });
    },
    gotopermanentDelete: function (org_id, org_name) {
      this.openDeleteModal = true;
      this.organization = org_id;
      this.org_name = org_name;
    },
    permanentDelete: function () {
      //   this.organization = org_id;
      let formData = new FormData();
      formData.append("org_id", this.organization);

      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: formData,
        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/hard-delete-organization/",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.openDeleteModal = false;
        this.load();
      });
    },
  },
};
</script>